import { useContext, useEffect, useMemo, useState } from 'react';

import { VegaBox, VegaFlex, VegaIcon, VegaLoadingIndicator } from '@heartlandone/vega-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { UserContext } from 'components/user/auth/userContext';
import { vegaDark, vegaLight, VegaMuiThemeTypes } from 'theme/theme';
import { AppContext } from 'types/app';
import { ChartData, ChartDateRange, Comparison, DataPoint } from 'types/chart';
import { Events } from 'types/events';
import tracker from 'utility/eventTracking';
import { DeviceIsMobile } from 'utility/showOnDevice';

import { calculateYValue } from './Chart.util';

import styles from '../index.module.css';

const AreaChart = dynamic(() => import('components/charts/areaChart'), { ssr: false });
const ComparisonChart = dynamic(() => import('components/charts/comparisonChart'), { ssr: false });

export interface ChartProps {
  selection: ChartDateRange;
  stroke: string;
  fill: string;
  data: ChartData | undefined;
  isLoading: boolean;
  isError: boolean;
  comparison?: {
    data: ChartData | undefined;
    isLoading: boolean;
    isError: boolean;
  };
  yValueIsCurrency?: boolean;
  allowDecimals?: boolean;
}

const lengthFactor = 25;

function Chart({
  selection,
  fill,
  stroke,
  yValueIsCurrency,
  data,
  isLoading,
  isError,
  comparison = { data: undefined, isLoading: false, isError: false },
  allowDecimals,
}: ChartProps) {
  const isMobile = DeviceIsMobile();
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const appInsights = useAppInsightsContext();

  const theme = appContext.theme === VegaMuiThemeTypes.Dark ? vegaDark : vegaLight;
  const [resizeSupported, setResizeSupported] = useState(false);
  const { t } = useTranslation('reports');

  const dataPoints: DataPoint[] = useMemo(
    () => data?.dataPoints ?? [],
    [data?.dataPoints],
  );

  const comparisonData: DataPoint[] | undefined = useMemo(
    () => comparison.data?.dataPoints ?? undefined,
    [comparison.data?.dataPoints],
  );

  const yMax = useMemo(() => calculateYValue(dataPoints, comparisonData, Math.max), [dataPoints, comparisonData]);

  const yMin = useMemo(() => calculateYValue(dataPoints, comparisonData, Math.min), [dataPoints, comparisonData]);

  const isLarge =
    (data?.dataPoints ?? []).length > lengthFactor || isMobile || selection.range === Comparison.CUSTOM;
  const error = comparison.isError || isError;

  if (error) {
    tracker.trackEvent(
      Events.CHART_ERROR,
      { customerId: userContext.activeCustomer, selection: selection, error: error },
      appInsights,
    );
  }

  let contents;

  useEffect(() => {
    setResizeSupported(typeof window !== 'undefined' && 'ResizeObserver' in window);
  }, [resizeSupported]);

  if (isLoading) contents = <VegaLoadingIndicator role="progressbar" />;
  else if (!isLoading && !resizeSupported) {
    contents = (
      <VegaBox className={styles.versionWarning} data-testid="version-warning">
        <VegaIcon icon="fas fa-warning" size="size-16" />
        <br />
        {t('errorMessages.outDatedBrowser')}
        <br /> {t('errorMessages.pleaseUpdate')}
      </VegaBox>
    );
  } else if (!isLoading && dataPoints.length && !error) {
    contents = (
      <>
        <VegaBox className={styles.title} color="text-primary">
          {data && t(data.title)}
        </VegaBox>
        {selection.comparison != null ? (
          <ComparisonChart
            data={dataPoints}
            comparisonData={comparisonData ?? []}
            isLarge={isLarge}
            yMax={yMax}
            yMin={yMin}
            isCurrency={yValueIsCurrency}
            fill={fill}
            stroke={stroke}
          />
        ) : (
          <AreaChart
            data={dataPoints}
            isLarge={isLarge}
            yMax={yMax}
            yMin={yMin}
            isCurrency={yValueIsCurrency}
            fill={fill}
            stroke={stroke}
            allowDecimals={allowDecimals}
          />
        )}
      </>
    );
  } else
    contents = (
      <VegaBox
        className={styles.emptyChart}
        style={{
          borderBottom: `2px solid ${theme.BgPage}`,
          borderLeft: `2px solid ${theme.BgPage}`,
        }}
      >
        <VegaBox color="text.primary">
          {isError && !comparison.isError ? t('errorMessages.errorFetching') : t('errorMessages.notEnoughData')}
        </VegaBox>
      </VegaBox>
    );
  return (
    <VegaFlex
      direction="col"
      gap="size-24"
      style={{ width: '100%' }}
      justifyContent="start"
      alignItems="start"
      className={isLarge ? styles.chartContainerRow : styles.chartContainer}
    >
      {contents}
    </VegaFlex>
  );
}

export default Chart;
