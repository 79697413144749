import { DataPoint } from 'types/chart';

export type Operation = (...values: number[]) => number;

export function calculateYValue(
  dataPoints: DataPoint[],
  comparisonData: DataPoint[] | undefined,
  operation: Operation,
) {
  if (!dataPoints.length) return 0;

  let yArray = dataPoints.flatMap((x) => parseFloat(x.yValue));

  if (comparisonData) {
    yArray = yArray.concat(comparisonData.flatMap((x) => parseFloat(x.yValue)));
  }

  const yValue = operation(...yArray);
  const exponential = Math.floor(Math.log10(Math.abs(yValue)));
  const calcAmount = Math.pow(10, exponential);

  const isOperationMin = operation === Math.min;
  let result = Math.round(yValue / calcAmount) * calcAmount || 0;
  result = isOperationMin ? result - calcAmount : result + calcAmount;
  return result > 0 && isOperationMin ? 0 : result;
}
