import { useState } from 'react';

import { SelectSourceItem } from '@heartlandone/vega';
import { VegaBox, VegaInputSelect } from '@heartlandone/vega-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'next-i18next';

import { ChartDateRange, Comparison, DateOptions } from 'types/chart';
import { Events } from 'types/events';
import { DefaultDateFormat } from 'types/reports';
import tracker from 'utility/eventTracking';

import ComparisonPicker from './comparisonPicker';
import CustomComparisonPicker from './customRangeComparisonPicker';
import CustomRangePicker from './customRangePicker';
import Legend from './legend';
import { colorAccentPrimaryStroke, colorAccentSecondaryStroke } from '../constants';

import styles from './index.module.css';

interface Props {
  selection: ChartDateRange;
  setSelection: (selection: ChartDateRange) => void;
}

const ChartRangeSelection: React.FC<Props> = ({ selection, setSelection }) => {
  const appInsights = useAppInsightsContext();
  const { t } = useTranslation('reports');
  const [showCustom, setShowCustom] = useState<boolean>(selection.range === Comparison.CUSTOM);

  const getDateOptions = (): SelectSourceItem[] => {
    let defaultOptions = DateOptions.map((x) => ({ displayName: t('dateRange.' + x.range), id: t(x.range) }));
    let customOption = { displayName: t('dateRange.custom'), id: Comparison.CUSTOM };

    return [...defaultOptions, customOption];
  };

  const handleTimeFrameSelect = (text: string) => {
    setShowCustom(text === Comparison.CUSTOM);
    const current = DateOptions.find((x) => x.range === text);
    if (current && !isEqual(current, selection)) {
      let newSelection = current;
      if (current.comparison !== Comparison.CUSTOM && selection.comparison !== Comparison.CUSTOM) {
        newSelection = { ...current, comparison: selection.comparison ?? current.comparison };
      }

      setSelection(newSelection);

      tracker.trackEvent(Events.GRAPH_SELECTED, { range: newSelection.range }, appInsights);
    }
  };

  const handleCustomRangeSelect = (newSelection: ChartDateRange) => {
    if (selection.end !== newSelection.end || selection.start !== newSelection.start) {
      setSelection(newSelection);
      tracker.trackEvent(Events.GRAPH_SELECTED, { range: newSelection.range }, appInsights);
    }
  };

  const handleCompareToSelect = (newSelection: ChartDateRange) => {
    setSelection(newSelection);
    tracker.trackEvent(
      Events.GRAPH_SELECTED,
      {
        range: newSelection.range,
        comparison: newSelection.comparison,
        compareStart: newSelection.compareStart,
        compareEnd: newSelection.compareEnd,
      },
      appInsights,
    );
  };

  return (
    <>
      <VegaBox className={styles.container}>
        <VegaBox className={styles.buttonRow}>
          <VegaInputSelect
            size={'small'}
            label={t('timeFrame')}
            value={showCustom ? Comparison.CUSTOM : selection.range}
            data-testid="transaction-chart-range-selector"
            style={{ width: '275px' }}
            onVegaChange={(e) => {
              let targetValue = e.target.value;

              handleTimeFrameSelect(targetValue.toString());
            }}
            className={styles.select}
            source={getDateOptions()}
          />
        </VegaBox>
        {showCustom && (
          <VegaBox className={styles.buttonRow}>
            <CustomRangePicker selection={selection} onClick={handleCustomRangeSelect} />
          </VegaBox>
        )}
        <VegaBox className={styles.buttonRow}>
          {showCustom ? (
            <CustomComparisonPicker selection={selection} onSelect={handleCompareToSelect} />
          ) : (
            <ComparisonPicker selection={selection} onSelect={handleCompareToSelect} />
          )}
        </VegaBox>
      </VegaBox>
      <VegaBox className={styles.legendRow}>
        {(selection.comparison === Comparison.LAST_MONTH || selection.comparison === Comparison.LAST_YEAR) && (
          <>
            <Legend start={selection.start} end={selection.end} color={colorAccentSecondaryStroke} t={t} />
            <Legend
              color={colorAccentPrimaryStroke}
              comparison={selection.comparison}
              start={selection.start}
              end={selection.end}
              t={t}
            />
          </>
        )}
        {selection.compareStart && selection.compareEnd && (
          <>
            <Legend
              color={colorAccentSecondaryStroke}
              comparison={selection.comparison}
              start={selection.start}
              end={selection.end}
              formatDate={DefaultDateFormat}
            />
            <Legend
              start={selection.compareStart}
              end={selection.compareEnd}
              color={colorAccentPrimaryStroke}
              formatDate={DefaultDateFormat}
            />
          </>
        )}
      </VegaBox>
    </>
  );
};

export default ChartRangeSelection;
